import './assets/scss/app.scss'
import './assets/css/bootstrap.min.css'
import 'vue3-toastify/dist/index.css';

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import * as Sentry from "@sentry/vue";


import { Plugin } from 'vue-responsive-video-background-player'
import VueNavigationBar from 'vue-navigation-bar';
import 'vue-navigation-bar/dist/vue-navigation-bar.css';

import es from "./lang/es.json"
import en from "./lang/en.json"

import {createI18n} from "vue-i18n";

const i18n = createI18n({
    locale: navigator.language,
    fallbackLocale: "en",
    messages: { es, en },
    legacy: false
})


let app = createApp(App)
app.use(store)
app.use(router)
app.use(i18n)
app.use(Plugin)
app.component('vue-navigation-bar', VueNavigationBar)

Sentry.init({
    app,
    dsn: "https://542021b1197cdc53ec26fe8b7abaddef@o4508373199421440.ingest.us.sentry.io/4508373201125376",
    integrations: [
        Sentry.browserTracingIntegration({ router }),
        Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0,
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
});



app.mount('#app')
